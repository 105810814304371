import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <div className="container">
      <h1 className="content-center">404 nicht gefunden.</h1>
      <h3>
        „die beste methode, das leben angenehm zu verbringen, ist, guten kaffee
        zu trinken. wenn man keinen haben kann, so soll man versuchen, so heiter
        und gelassen zu sein, als hätte man guten kaffee getrunken.“
      </h3>      
    </div>
  </Layout>
)

export default NotFoundPage
